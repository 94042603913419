<template>
  <div>
    <div class="col float-left">
      <c-select
        :comboItems="deptItems"
        class="assessDept"
        type="edit"
        itemText="assessDeptName"
        itemValue="assessDeptCd"
        name="assessDeptCd"
        label=""
        v-model="assessDeptCd"
      ></c-select>
      <font class="text-negative" style="font-size:1em;font-weight:800;">
        (※ 부서를 지정하여 평가할 공정을 확인하세요.)
      </font>
    </div>
    <div class="col float-right">
      <q-btn-group outline>
        <!-- 업체요청 반려 -->
        <c-btn
          v-show="param.vendorFlag&&isVendorRequest"
          label="LBL0001160"
          icon="front_hand" color="red-3"
          @btnClicked="returnDialogOpen" />
        <!-- <c-btn 
          v-show="!deleteDisabled" 
          label="지난 위험성평가 재검토"
          icon="autorenew" 
          @btnClicked="reCheckRisk" /> -->
        <!-- 위험성평가 삭제 -->
        <c-btn 
          v-show="!deleteDisabled" 
          label="LBL0001161"  
          icon="remove" 
          @btnClicked="removePlan" />
          <!-- 평가완료 -->
        <c-btn 
          v-show="completeFlag"
          :isSubmit="isComplete"
          :url="completeUrl"
          :param="assessPlan"
          mappingType="PUT"
          label="LBL0001162" 
          icon="check"
          @beforeAction="completeAction"
          @btnCallback="completeActionCallback" />
      </q-btn-group>
    </div>
    <c-tab
      ref="riskActionTab"
      type="vertical"
      :tabItems="filterTabItems"
      :height.sync="height"
      v-model="tab"
    >
      <template v-slot:default="tab">
          <!-- :is="scenarioComponent" -->
        <component
          :is="tab.component"
          :key="tab.key"
          :height="height"
          :process.sync="tab.process"
          :param.sync="param"
          :planUpdateBtnData="param.planUpdateBtnData"
          @assessComplete="assessComplete"
          @research="research"
          @closePopup="closePopup"
        />
      </template>
    </c-tab>
    <q-dialog v-model="returnDialog.show" persistent>
      <q-card style="min-width: 450px">
        <q-form ref="editForm2">
          <!-- 반려 사유 -->
          <c-card title="LBL0001163" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <!-- 반려 -->
                <c-btn
                  v-show="editable" 
                  :isSubmit="isReturn"
                  :url="returnUrl"
                  :param="returnData"
                  mappingType="PUT"
                  label="LBL0000777" 
                  icon="front_hand" color="red-3"
                  @beforeAction="returnAssess"
                  @btnCallback="returnAssessCallback" />
                <!-- 닫기 -->
                <c-btn 
                  label='LBLCLOSE' 
                  icon="cancel" 
                  @btnClicked="closeDialog"
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-12">
                <c-textarea
                  :editable="editable"
                  :rows="5"
                  label=""
                  name="returnRemark"
                  v-model="returnData.returnRemark">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </q-form>
      </q-card>
    </q-dialog>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'riskAction',
  props: {
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
        vendorFlag: false,
        ramTechniqueCd: '',
        searchRiskReduce: {
          flag: '',
          isSearch: false,
        },
        searchScenario: {
          flag: '',
          isSearch: false,
        },
        planUpdateBtnData: {
          title: 'LBLPLAN',
          flag: false,
          research: '',
          planResearch: '',
        },
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      tab: '',
      tabItems: [],
      returnData: {
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
        returnRemark: '',
        chgUserId: '',
      },
      returnDialog: {
        show: false,
      },
      deptItems: [],
      assessDeptCd: null,
      isVendorRequest: false,
      editable: true,
      isComplete: false,
      isReturn: false,
      listUrl: '',
      completeUrl: '',
      returnUrl: '',
      deletePlanUrl: '',
      recheckSaveUrl: '',
      popupOptions: {
        isApproval: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    completeFlag() {
      return this.tabItems 
        && this.tabItems.length > 0
        && this.$_.findIndex(this.tabItems, (item) => {
              return item.process.ramProcessAssessStepCd !== 'RPA0000005'
            }) === -1 
        && this.param.ramStepCd === 'RRS0000010'
    },
    assessPlan() {
      return {
        ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId,
        ramStepCd: this.param.ramStepCd,
        chgUserId: this.$store.getters.user.userId,
      }
    },
    deleteDisabled() {
      return this.param.ramStepCd === 'RRS0000015'
    },
    techCheck() {
      // HAZOP, CHARM만 기능 비활성화
      return false
      // return this.param.ramTechniqueCd === 'RT00000001'
      //   || this.param.ramTechniqueCd === 'RT00000030'
    },
    scenarioComponent() {
      let components = null;
      if (this.param.ramTechniqueCd === 'RT00000001') {
        // HAZOP
        components = () => import(`${'@/pages/ram/hazop/action/hazopActionDetail.vue'}`)
      } else if (this.param.ramTechniqueCd === 'RT00000005') {
        // K-PSR
        components = () => import(`${'@/pages/ram/kpsr/action/kpsrActionDetail.vue'}`)
      } else if (this.param.ramTechniqueCd === 'RT00000010') {
        // Check-list
        components = () => import(`${'@/pages/ram/checklist/action/checklistActionDetail.vue'}`)
      } else if (this.param.ramTechniqueCd === 'RT00000015') {
        // JRA
        components = () => import(`${'@/pages/ram/jsa/action/jsaActionDetail.vue'}`)
      } else if (this.param.ramTechniqueCd === 'RT00000020') {
        // KRAS
        components = () => import(`${'@/pages/ram/kras/action/krasScenario.vue'}`)
      } else if (this.param.ramTechniqueCd === 'RT00000025') {
        // 4M
        components = () => import(`${'@/pages/ram/4m/action/4mScenario.vue'}`)
      } else if (this.param.ramTechniqueCd === 'RT00000030') {
        // CHARM
        components = () => import(`${'@/pages/ram/charm/action/charmActionDetail.vue'}`)
      } else if (this.param.ramTechniqueCd === 'RT00000035') {
        // 3단계 판단법
        components = () => import(`${'@/pages/ram/third/action/thirdScenario.vue'}`)
      }
      return components;
    },
    drawComponent() {
      let components = null;
      if (this.param.ramTechniqueCd === 'RT00000001') {
        // HAZOP
        components = () => import(`${'@/pages/ram/hazop/action/hazopScenario.vue'}`)
      } else if (this.param.ramTechniqueCd === 'RT00000005') {
        // K-PSR
        components = () => import(`${'@/pages/ram/kpsr/action/kpsrScenario.vue'}`)
      }
      return components;
    },
    filterTabItems() {
      let items = [];
      if (this.tabItems && this.tabItems.length > 0) {
        items = this.tabItems;
        // items = this.$_.cloneDeep(this.tabItems);
        if (this.assessDeptCd) {
          items = this.$_.filter(items, { assessDeptCd: this.assessDeptCd })
        }
      }
      return items;
    }
  },
  watch: {
    'param.searchScenario.flag'() {
      this.getList();
    },
    'param.planUpdateBtnData.research'() {
      this.getList();
    },
    assessDeptCd: {
      handler: function () {
        if (this.filterTabItems && this.filterTabItems.length > 0) {
          this.tab = this.filterTabItems[0].name
        }
      },
      deep: true,
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.targetProcess.list.url;
      this.completeUrl = transactionConfig.ram.assessPlan.complete.url
      this.returnUrl = transactionConfig.ram.assessPlan.return.url
      this.deletePlanUrl = transactionConfig.ram.assessPlan.delete.url
      this.recheckSaveUrl = transactionConfig.ram.assessPlan.recheck.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramTechniqueCd: this.param.ramTechniqueCd,
        ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId
      };
      this.$http.request((_result) => {
        this.tabItems = [];
        this.deptItems = [];
        this.$_.forEach(_result.data, item => {
          this.tabItems.push({
            key: uid(),
            name: item.processCd, 
            icon: 'work_outline', 
            label: item.processName, 
            process: item, 
            assessDeptCd: item.assessDeptCd,
            component: this.scenarioComponent
          })
          if (this.$_.findIndex(this.deptItems, { assessDeptCd: item.assessDeptCd }) === -1) {
            this.deptItems.push({
              assessDeptCd: item.assessDeptCd,
              assessDeptName: item.assessDeptName,
            })
          }
          this.isVendorRequest = item.vendorRequestFlag === 'Y'
        })

        if (this.deptItems && this.deptItems.length > 0) {
          let idx = this.$_.findIndex(this.deptItems, { assessDeptCd: this.$store.getters.user.deptCd });
          if (idx > -1) {
            this.$set(this.$data, 'assessDeptCd', this.deptItems[idx].assessDeptCd)
          } else {
            this.$set(this.$data, 'assessDeptCd', null)
          }
        }

        this.tab = _result.data[0].processCd
        this.$refs['riskActionTab'].setValue(this.tab)
      },);
    },
    reCheckRisk() {
      /**
       * 재검토
       * 
       *  - 지난 위험성평가에서 진행한 유해위험요인에 대해서 재검토 진행
       *  - 위험성평가 한 개 이상 선택
       *  - 대상 공정 포함 아래 정보 summary되어 추가
       */
      this.popupOptions.title = "LBL0001051"; // 위험성평가 재검토 검색
      this.popupOptions.param = {
        ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId,
        ramTechniqueCd: this.param.ramTechniqueCd,
        vendorFlag: this.param.vendorFlag,
        actionFlag: true,
      };
      this.popupOptions.target = () => import(`${"@/pages/ram/reCheckAssessmentPop.vue"}`);
      this.popupOptions.width = "90%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeRecheckPopup;
    },
    closeRecheckPopup(data, summaryData) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$http.url = this.recheckSaveUrl;
        this.$http.type = 'POST';
        this.$http.param = {
          ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId,
          ramTechniqueCd: this.param.ramTechniqueCd,
          regUserId: this.$store.getters.user.userId,
          recheckData: summaryData,
          ramRiskAssessmentPlanIds: this.$_.map(data, 'ramRiskAssessmentPlanId')
        };
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getList();
        },);
      }
    },
    assessComplete(stepCd) {
      this.getList();
      // stepper 이동
      if (stepCd === 'RRS0000015') {
        this.$emit('emitStep', {
          name: 'stepBystep',
          param: stepCd
        })
      }
    },
    research(place) {
      this.$emit('emitStep', {
        name: 'research',
        param: {
          place: place,
        }
      })
    },
    completeAction() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGCOMPLETE', // 완료하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.assessPlan.chgUserId = this.$store.getters.user.userId
          this.assessPlan.ramStepCd = 'RRS0000015'

          this.isComplete = !this.isComplete
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    completeActionCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      // 계획 상세 조회
      this.getList();
      // stepper 이동
      this.$emit('emitStep', {
        name: 'stepBystep',
        param: this.assessPlan.ramStepCd
      })
    },
    returnDialogOpen() {
      this.returnDialog.show = true;
    },
    closeDialog() {
      this.returnDialog.show = false;
      Object.assign(this.$data.returnDialog, this.$options.data().returnDialog);
    },
    returnAssess() {
      if (!this.returnData.returnRemark) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSG0000394', // 반려사유를 입력하세요.
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSG0000395', // 업체요청 반려하시겠습니까?
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$_.extend(this.returnData, this.assessPlan)

          this.isReturn = !this.isReturn
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    returnAssessCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.closeDialog();
      // 계획 상세 조회
      this.getList();
    },
    removePlan() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deletePlanUrl, this.param.ramRiskAssessmentPlanId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.closePopup()
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    closePopup() {
      this.$emit('emitStep', {
        name: 'closePopup'
      })
    },
  }
};
</script>
<style lang="sass">
.assessDept
  display: inline-block
  min-width: 200px
  label
    padding: 0 !important
    .q-field__inner
      padding: 0 !important
      padding-left: 10px !important
</style>